import {
  container,
  title,
  main,
  mainRaised,
  mrAuto,
  blackColor,
  mlAuto,
  description,
  section
} from "assets/jss/material-kit-pro-react.jsx";

const benefitStyle = {
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  description,
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
    "&, & + h4": {
      color: blackColor
    },
    textAlign: `center`
  },
  h2title: {
    fontWeight: `600`,
    fontSize: `1.7rem`,
  },
  textLeft: {
    textAlign: `left`
  },
  block: {
    color: `inherit`,
    padding: `0.9375rem`,
    fontWeight: `500`,
    fontSize: `12px`,
    textTransform: `uppercase`,
    borderRadius: `3px`,
    textDecoration: `none`,
    position: `relative`,
    display: `block`
  },
  inlineBlock: {
    display: `inline-block`,
    padding: `0px`,
    width: `auto`
  },
  list: {
    marginBottom: `0`,
    padding: `0`,
    marginTop: `0`
  },
  left: {
    float: `left!important`,
    display: `block`
  },
  right: {
    padding: `15px 0`,
    margin: `0`,
    float: `right`
  },
  icon: {
    width: `18px`,
    height: `18px`,
    top: `3px`,
    position: `relative`
  },
  noShadow: {
    boxShadow: `none !important`,
    border: `1px solid !important`,
    borderColor: `#eee !important`
  },
  section: {
    ...section,
    padding: `70px 0px`
  },

  textBold: {
    fontWeight: `bold`
  },
  coloring: {
    color: `blue`
  },
  table: {
    "@media (max-width:750px)": {
      paddingLeft: "5px",
      paddingRight: "5px",
      flex: "1 1 auto",
      maxHeight: "none",
      overflow: "auto",
      display: `inline-block`,
    },
  },
  tableStyle: {
    marginLeft: "15px",
  },
  headCell: {
    fontSize: "15px",
    fontWeight: "bold",
    textShadow: "0px 0px 0px black",
    border: "1px solid black",
    color: "black",
    textAlign: "center",
  },
  tableCell: {
    border: `1px solid black`,
    padding: "12px",
    fontSize: "14px",
    marginBottom:'2%',
    textAlign: "center",
  },
  tableCellwidth: {
    border: `1px solid black`,
    padding: "12px",
    fontSize: "14px",
    marginBottom:'2%',
    textAlign: "center",
    width:'200px'
  },
  leadsFormContainer: {
    display: `flex`,
    flexDirection: `row`,
    "& img": {
      "@media (max-width: 992px)": {
        display: `none`,
      },
    },
  },
  leadsForm: {
    padding: `3% 3% 0 3%`,
    width: `100%`,
    "@media (max-width: 992px)": {
      padding: `2% 0% 2% 0%`
    }
  }
};

export default benefitStyle;
